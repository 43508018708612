export class NumberInputValueConverter {
    toView(number, factor, digits) {
        if (number === null || number === undefined || number === "") {
            return null;
        }

        factor = parseFloat(factor);

        //Undefined, null, 0, NaN
        if (!factor || factor === 1) {
            return number;
        }

        number = parseFloat(
            parseFloat(number).toPrecision(6) /
                parseFloat(factor).toPrecision(6)
        ).toPrecision(6);

        const decimalPart =
            parseFloat(
                number
                    .toString()
                    .split(".")[1]
                    .split("")
                    .filter((val) => val != 0)
                    .join("")
            ) || 0;

        number = parseFloat(Math.floor(number) + "." + decimalPart);

        if (factor < 1 && Math.round(number) != number) {
            if (decimalPart.toString().length > (digits || 2)) {
                number = parseFloat(number).toFixed(
                    digits ?? -1 * Math.log10(factor)
                );
            }
        }

        return number;
    }

    fromView(number, factor) {
        if (number === null || number === undefined || number === "") {
            return null;
        }

        factor = parseFloat(factor);

        //Undefined, null, 0, NaN
        if (!factor || factor === 1) {
            return number;
        }

        return parseFloat(number) * factor;
    }
}
