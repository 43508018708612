

export class Nl2BrValueConverter
{

    toView(str)
    {
        if (!str) {
            return '';
        }

        return str.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br>$2');
    }

}