import {customElement, bindable, inject} from 'aurelia-framework';

@customElement("sio-tab-table")
export class TabContentTable {
    @bindable object;
    @bindable tab;

    additionalFetchConditions(tab, object) {
        const refField = this.tab.preset.properties.refField;

        return {
            [refField]: {$eq: {id: object.id, modelId: object.modelId}}
        };
    }
}
