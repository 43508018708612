import {bindable, customElement, inject} from 'aurelia-framework';
import {EventAggregator} from "aurelia-event-aggregator";
import {Client} from "../../api/client";

import "./count.less";

@customElement('sio-tab-count')
@inject(EventAggregator, Client)
export class TabCount {

    @bindable object;
    @bindable tab;

    constructor(ea, client) {
        this.ea = ea;
        this.client = client;
    }

    _loadCount() {
        if (!this.tab.count.modelId) {
            this.count = null;
            return;
        }

        this.loading = true;

        this.client.get('tab/' + this.object.modelId + '/count/' + this.tab.id + '/' + this.object.id)
            .then(counter => {
                if (counter != null) {
                    this.count = counter;
                }
            }, error => {
                this.count = null;
            })
            .finally(() => this.loading = false);
    }

    attached() {
        this._loadCount();

        this.subscription = this.ea.subscribe('sio_form_post_submit', response => {

            if (response.config.modelId !== this.tab.count.modelId) {
                return;
            }

            this._loadCount();
        });
    }

    detached() {
        this.subscription.dispose();
    }
}
