import {useContext} from "react"
import {ActionButton} from "../../action/react/action-button";
import AureliaContext from "../../utilities/aurelia-context";

export default function Panel({heading, actionConfig, reference, onSubmitted, children, additionalHeader, ...props}) {
    const {i18n} = useContext(AureliaContext)

    return (
        <div className="panel" {...props}>
            <div className="panel-heading">
                {!!(actionConfig || additionalHeader) && (
                    <div className="float-right">
                        {additionalHeader}
                        {!!actionConfig && (
                            <ActionButton size="xs" context={reference} config={actionConfig} onSubmitted={onSubmitted}/>
                        )}
                    </div>
                )}

                {"string" === typeof heading ? i18n.tr(heading) : heading}
            </div>

            <div className="panel-body">
                {children}
            </div>
        </div>
    )
}
