

export class TimeInputValueConverter
{

    toView(number)
    {
        if (number === null || number === undefined || number === '') {
            return null;
        }

        console.log('BACKBACK', number);

        return this.toHours(number);
    }

    toHours(n) {
        var rhours = Math.floor(n);
        var minutes = (n - rhours) * 60;
        var rminutes = Math.round(minutes);
        return rhours.toString().padStart(2, '0') + rminutes.toString().padStart(2, '0');
    }

    fromView(number)
    {
        if (number === null || number === undefined || number === '') {
            return null;
        }

        let hours = number.substring(0, 2);
        let minutes = number.substring(2, 4);

        console.log('LOGLOG', number, hours, minutes);

        return parseFloat(hours) + (parseFloat(minutes) / 60);
    }
}
