import {inject} from 'aurelia-framework';
import {CurrencyValueConverter} from "../currency/currency-value-converter";

@inject(CurrencyValueConverter)
export class UpchargeValueConverter
{
    constructor(currencyValueConverter)
    {
        this.currencyValueConverter = currencyValueConverter;
    }

    toView(item, list)
    {
        if (!list || list.length == 0) {
            return '';
        }

        if (item.price.amount == 0) {
            return '';
        }

        let compare = item.price.amount;
        let cheapestItem = null;

        list.forEach((listItem) => {

            if (!listItem.price || listItem.price.amount === 0) {
                return;
            }

            if (!cheapestItem || listItem.price.amount < cheapestItem) {
                cheapestItem = listItem.price.amount;
            }
        });

        if (!cheapestItem || (compare - cheapestItem) === 0) {
            return null;
        }

        let money = {
            amount: compare - cheapestItem,
            currency: item.price.currency,
        };

        let result = this.currencyValueConverter.toView(money);

        if (result) {
            return '(+' + result + ')';
        } else {
            return '';
        }
    }

}