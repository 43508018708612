import {inject} from "aurelia-framework";
import {UserClient} from "../api/user-client";

@inject(UserClient)
export class ClipValueConverter {
    constructor(user) {
        this.user = user;
    }

    toView(value, clipped) {
        if (this.user.getUser()?.settings?.userSettings?.clipMultilineContent && clipped) {
            return value + ' clipped';
        }

        return value;
    }
}
