import * as _ from 'lodash';

export class AccessValueValueConverter
{

    toView(value, property)
    {
        if (_.has(value, property)) {
            return _.get(value, property);
        }

        return '';
    }

}