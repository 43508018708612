import {DialogController, DialogService} from "aurelia-dialog";
import {inject, LogManager, PLATFORM} from "aurelia-framework";
import {Router} from "aurelia-router";
import {ViewResolver} from "./view-resolver";
import {I18N} from "aurelia-i18n";
import {UnsavedChangesChecker} from "../form/unsaved-changes-checker";
import * as _ from "lodash";
import {UnsavedChanges, UnsavedChangesPrevent} from "../form/unsaved-changes-prevent";
import {UserClient} from "../api/user-client";

const logger = LogManager.getLogger('DialogView');
//logger.setLevel(LogManager.logLevel.none); // Disable logging for this class by setting "LogManager.logLevel.none"

@inject(
    I18N,
    Router,
    ViewResolver,
    DialogController,
    DialogService,
    UnsavedChangesChecker,
    UserClient
)
export class DialogView
{
    viewModel = PLATFORM.moduleName('./view-container');

    constructor(
        I18N,
        router,
        viewResolver,
        dialogController,
        dialogService,
        unsavedChangesChecker,
        userClient
    ) {
        this.i18n = I18N;
        this.router = router;
        this.dialogController = dialogController;
        this.dialogService = dialogService;
        this.unsavedChangesChecker = unsavedChangesChecker;
        this.viewResolver = viewResolver;
        this.userClient = userClient;
    }

    async activate(params)
    {
        let user = this.userClient.getUser();

        this.dialogController.settings.overlayDismiss = user?.settings?.userSettings?.closeDialogsWhenClickingOutside ?? false;

        this.params = params;

        logger.debug('params BIND', params);

        const viewOptions = await this.viewResolver.resolve(params);

        if (viewOptions.error) {
            this.error = viewOptions.error;
            return;
        }

        this.error = null;

        this.view = viewOptions.view;

        this.context = viewOptions.context;
        this.context.params = params;
        this.context.dialogController = this.dialogController;

        this.originalUnsavedChanges = _.clone(UnsavedChanges.changes);

        logger.debug('view BIND', this.view, this.context);
    }

    deactivate()
    {
        logger.debug('deactivate');
        UnsavedChangesPrevent.cleanChanges();
    }

    async canDeactivate()
    {
        return this.unsavedChangesChecker.canDeactivate(this.originalUnsavedChanges);
    }
}
