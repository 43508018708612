export class ThumbnailValueConverter
{
    toView(previewUrl, width = '-', height = '-', mode = null)
    {
        // Same logic as in ThumbnailTwigExtension from frontend
        if (!previewUrl) {
            return '';
        }

        const resizedImage = previewUrl.replace('$width', width).replace('$height', height);

        let modifiedImage = resizedImage;
        if (mode) {
            if (mode === true) {
                mode = 'crop';
            }

            modifiedImage = resizedImage.replace('/resize/', `/${mode}/`);
        }

        return modifiedImage;
    }
}
