import {inject, InlineViewStrategy} from "aurelia-framework";
import {ViewContextResolver} from "./view-context-resolver.js";
import {ModuleConfigClient} from "../api/module-config-client.js";
import * as _ from "lodash";

@inject(
    ViewContextResolver,
    ModuleConfigClient
)
export class ViewResolver
{
    constructor(
        viewContextResolver,
        moduleConfigClient
    ) {
        this.viewContextResolver = viewContextResolver;
        this.moduleConfigClient = moduleConfigClient;
    }

    async resolve(params)
    {
        const viewOptions = {};

        if (params.content) {
            viewOptions.view = {
                viewStrategy: new InlineViewStrategy('<template>' + params.content + '</template>')
            };
            viewOptions.context = {};
        } else {
            viewOptions.view = _.cloneDeep(await this.moduleConfigClient.getView(params.moduleId + '/' + params.viewId));
            viewOptions.view.viewStrategy = this.viewContextResolver.resolveViewStrategy(viewOptions.view);

            try {
                viewOptions.context = await this.viewContextResolver.resolveViewContext(viewOptions.view, params) || {};

            } catch (e) {
                return {
                    error: 403 === e.status ? 'sio.access_denied' : 'view.could_not_load'
                };
            }
        }

        if (!viewOptions.view.title && params.title) {
            viewOptions.view.title = params.title;
        }

        return viewOptions;
    }
}
