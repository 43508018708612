export class MilitaryTimeValueConverter
{
    toView(str)
    {
        if (null == str || '' === str) return '';

        return str.slice(0, 2) + ':' + str.slice(2);
    }

}
