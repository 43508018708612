import {customElement, bindable} from 'aurelia-framework';

@customElement('google-maps-link')
export class GoogleMapsLink
{
    @bindable
    address;

    addressChanged()
    {
        if (this.isEmptyAddress(this.address)) {
            this.url = null;
            return;
        }

        let url = 'http://maps.google.com/?q=';

        //Todo country name lookup
        url += encodeURIComponent(this.address.addressLine1 + ' ' + (this.address.addressLine2 ? this.address.addressLine2 : '') + ' ' + this.address.zip + ' ' + this.address.city + ' ' + this.address.country);

        this.url = url;
    }

    isEmptyAddress(address)
    {
        return !(
            !!address.addressLine1 ||
            !!address.addressLine2 ||
            !!address.zip ||
            !!address.city ||
            !!address.country
        );
    }
}
