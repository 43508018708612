import {
    Container,
    inject,
    LogManager,
    PLATFORM,
    RelativeViewStrategy,
    ViewCompiler,
    ViewResources,
    ViewSlot
} from "aurelia-framework";
import {activationStrategy, Router} from "aurelia-router";
import {ViewContextResolver} from "./view-context-resolver";
import {DialogService} from "aurelia-dialog";
import {I18N} from "aurelia-i18n";
import {EventAggregator} from "aurelia-event-aggregator";
import * as _ from "lodash";
import {ModuleConfigClient} from "../api/module-config-client";
import {Client} from "../api/client";
import {FlashService} from "../flash/flash-service";
import {UnsavedChangesChecker} from "../form/unsaved-changes-checker";
import {UnsavedChanges, UnsavedChangesPrevent} from "../form/unsaved-changes-prevent";

const logger = LogManager.getLogger('View');

@inject(
    Router,
    ModuleConfigClient,
    ViewContextResolver,
    DialogService,
    I18N,
    EventAggregator,
    ViewCompiler,
    Container,
    ViewResources,
    FlashService,
    UnsavedChangesChecker,
    Client
)
export class View {

    viewModel = PLATFORM.moduleName('./view-container');
    params = {};

    constructor(router,
                moduleConfigClient,
                viewContextResolver,
                dialog,
                i18n,
                ea,
                viewCompiler,
                container,
                viewResources,
                flashService,
                unsavedChangesChecker,
                client) {
        this.router = router;
        this.moduleConfigClient = moduleConfigClient;
        this.viewContextResolver = viewContextResolver;
        this.dialog = dialog;
        this.i18n = i18n;
        this.ea = ea;
        this.viewCompiler = viewCompiler;
        this.container = container;
        this.viewResources = viewResources;
        this.flashService = flashService;
        this.unsavedChangesChecker = unsavedChangesChecker;
        this.client = client;
    }

    determineActivationStrategy() {
        return activationStrategy.replace;
    }

    attached() {
        if (this.params.errorMessage != null) {
            this.flashService.error(this.params.errorMessage);
        }

        this.subscription = this.ea.subscribe('sio_form_post_submit', response => {

            if (this.view.modelId !== response.config.modelId) {
                return;
            }

            //Force clear cache, because order of subscribers is not fixed, and sometimes clear is not called before
            this.client.removeCache(response.config.modelId);
            this.client.removeCache('model/choice/' + response.config.modelId);

            this.updateView().then(data => {

            });
        });
    }

    detached() {
        this.subscription.dispose();
    }

    async activate(params, routeConfig) {

        this.params = params;
        this.error = null;
        this.routeConfig = routeConfig;

        //Keep original unsaved changes in case view is a form
        this.originalUnsavedChanges = _.clone(UnsavedChanges.changes);

        if (this.params.updateTitle == null) {
            this.params.updateTitle = true;
        }

        if (!params.moduleId) {
            let parts = params.viewId.split('/');
            params.moduleId = parts[0];
            params.viewId = parts[1];
        }

        try {
            this.view = await this.moduleConfigClient.getView(params.moduleId + '/' + params.viewId);

            this.view.viewStrategy = this.viewContextResolver.resolveViewStrategy(this.view);
        } catch (e) {
            this.error = 'view.could_not_load';

            return;
        }

        await this.updateView();
    }

    async updateView() {
// Update background color when defined otherwise set null to get default

        try {
            this.context = await this.viewContextResolver.resolveViewContext(this.view, this.params) || {};
        } catch (e) {
            if (403 === e.status) {
                this.error = 'sio.access_denied';
            } else {
                this.error = 'view.could_not_load';
            }

            return;
        }

        if (this.context?.object?.__alerts?.length) {
            this.alerts = this.context.object.__alerts.map(a => {
                if ("string" === typeof a) {
                    a = {text: a};
                }
                a.class ??= "info";
                return a;
            });
            delete this.context.object.__alerts;
        } else {
            delete this.alerts;
        }

        if (this.view.title && this.params.updateTitle) {
            console.debug('NAV MODEL', this.context);

            const trTitle = this.createTitle(this.view.title);

            let breadcrumbs = _.clone(this.view.breadcrumbs);
            breadcrumbs.push({label: trTitle});

            breadcrumbs = await Promise.all(breadcrumbs.map(async breadcrumb => {

                // avoid losing templates
                if (!breadcrumb.hasOwnProperty('_label')) {
                    breadcrumb._label = breadcrumb.label;
                }

                breadcrumb.label = this.createTitle(breadcrumb._label);

                if (!breadcrumb.hasOwnProperty('viewId')) {
                    return breadcrumb;
                }

                let viewId = breadcrumb.viewId;

                if (breadcrumb.viewId[0] === '@') {

                    let contextModelId = _.get(this.context.object, breadcrumb.viewId.substr(1))?.modelId;

                    if (!contextModelId) {
                        return breadcrumb;
                    }

                    let moduleViewId = await this.client.get(contextModelId + '/list', 60);

                    viewId = moduleViewId.displayView.split('/');
                } else {
                    viewId = viewId.split('/');
                }

                if (viewId.length !== 2) return breadcrumb;

                const params = {
                    moduleId: viewId[0],
                    viewId: viewId[1]
                };

                if (breadcrumb.hasOwnProperty('params')) {
                    for (let property in breadcrumb.params) {
                        if (!breadcrumb.params.hasOwnProperty(property)) {
                            continue;
                        }

                        let value = breadcrumb.params[property];

                        if ('@' === value[0]) {
                            value = _.get(this.context.object, value.substr(1));
                        }

                        params[property] = value;
                    }
                }

                breadcrumb.url = this.router.generate('view', params);

                const _getUrl = url =>
                    url && url.replace(/^(https?:)?\/\//, '').slice(url.indexOf('/')).substr(0, breadcrumb.url.length + 1) === (breadcrumb.url + '?') ?
                        url : undefined;

                breadcrumb.url = _getUrl(document.referrer, breadcrumb.url) ||
                    _getUrl(window.redirectLocationHref, breadcrumb.url) ||
                    breadcrumb.url;

                logger.debug('Trying to compare previous locations (previousLocation)', window.redirectLocationHref);
                logger.debug('Trying to compare previous locations (document.referrer)', document.referrer);
                logger.debug('Trying to compare previous locations (breadcrumb\'s content)', breadcrumb);

                return breadcrumb;
            }));

            //If view was changed in the meantime we should not update router
            if (this.deactivated) {
                return;
            }

            this.routeConfig.navModel.title = this.createPageTitle(trTitle);
            this.routeConfig.navModel.breadcrumbs = breadcrumbs;
        }


        if (this.context.object?.backgroundColor && (this.context.object?.modelId =="tourism/itinerary" || this.context.object?.modelId == "tourism/journey")){
            document.body.style.setProperty('--background-color', this.context.object?.backgroundColor ?? "#f3f3f3");
        }else{
            document.body.style.setProperty('--background-color', "#f3f3f3");
        }


    }

    getViewStrategy() {
        return new RelativeViewStrategy(this.params.hasOwnProperty('dialog') ? PLATFORM.moduleName('./dialog-view.html') : PLATFORM.moduleName('./view.html'));
    }

    createPageTitle(trTitle) {
        let pageTitle = '';
        if(this.context?.object?.label) {
            pageTitle = this.i18n.tr(this.context.object.label);
        } else {
            pageTitle = trTitle.replace(/<(?:.|\n)*?>/gm, '');
        }
        return pageTitle;
    }

    canDeactivate() {
        if (!this.dialog.hasActiveDialog) {

            console.log('CAN DEACTIVATE', this.originalUnsavedChanges);
            if (!this.unsavedChangesChecker.canDeactivate(this.originalUnsavedChanges)) {
                return false;
            }

            return true;
        }

        return this.dialog.controllers[this.dialog.controllers.length - 1].cancel();
    }

    deactivate() {
        this.deactivated = true;
        UnsavedChangesPrevent.cleanChanges();
    }

    createTitle(title) {
        let trTitle = '';

        try {
            trTitle = this.i18n.tr(title, {sprintf: this.context});

            // check if title is using Aurelia templating
            if (/\${/.test(trTitle) || /\<sio/.test(trTitle)) {

                const node = document.createElement('DIV');
                const slot = new ViewSlot(node, true);
                const view = this.viewCompiler
                    .compile('<template>' + trTitle + '</template>', this.viewResources)
                    .create(this.container, this.context);
                slot.add(view);
                slot.attached();
                view.bind(this.context);
                trTitle = node.innerHTML;
                slot.detached();
            }
        } catch (e) {

            try {
                //Todo improve and remove sprintf matching
                trTitle = this.i18n.tr(title);

                // check if title is using Aurelia templating
                if (/\${/.test(trTitle)) {

                    const node = document.createElement('DIV');
                    const slot = new ViewSlot(node, true);
                    const view = this.viewCompiler
                        .compile('<template>' + trTitle + '</template>', this.viewResources)
                        .create(this.container, this.context);
                    slot.add(view);
                    slot.attached();
                    view.bind(this.context);
                    trTitle = node.innerHTML;
                    slot.detached();
                }

            } catch (e) {
                console.error(e);
            }
        }

        return trTitle;
    }
}
