import {customElement, bindable} from 'aurelia-framework';

@customElement("sio-tab-overview")
export class TabContentOverview {
    @bindable object;
    @bindable tab;

    tabChanged()
    {
        const panels = this.tab.preset.panels;

        this.leftColumn = [];
        this.rightColumn = [];

        panels.forEach(panel => {
            if (panel.column === 'left') {
                this.leftColumn.push(panel);
            } else {
                this.rightColumn.push(panel);
            }
        });

        this.leftColumn.sort(function (a, b) {
            return b.priority - a.priority;
        });

        this.rightColumn.sort(function (a, b) {
            return b.priority - a.priority;
        });

        console.log(this.tab);

        if (this.tab.layout === 'right-sided') {
            this.layoutIndex = [
                'col-xs-12 col-md-4',
                'col-xs-12 col-md-8'
            ]
        } else {
            this.layoutIndex = [
                'col-xs-12 col-md-6',
                'col-xs-12 col-md-6'
            ]
        }
    }
}
