

export class ShrinkValueConverter
{

    toView(value, maxContentLength)
    {
        if (_.isString(value) && maxContentLength) {
            if (value.length > maxContentLength + 3) {
                // Remove tags
                value = value.replace(/(<([^>]+)>)/ig, '');
                return value.substr(0, maxContentLength) + ' … ';
            }
        }

        return value;
    }

}