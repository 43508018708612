import {bindable, customElement, inject, LogManager, NewInstance} from "aurelia-framework";
import {Client} from '../api/client';
import * as _ from "lodash";
import {UnsavedChanges} from "../form/unsaved-changes-prevent";
import {EventAggregator} from 'aurelia-event-aggregator';
import {UrlUtils} from "../utilities/url-utils";
import {WindowUtils} from "../utilities/window-utils";
import {OAuthClient} from "../api/oauth-client";
import {HttpClient} from "aurelia-fetch-client";

import "./wiki.less";

@customElement('sio-wiki')
@inject(Client, NewInstance.of(HttpClient), EventAggregator)
export class Wiki {

    @bindable sio = false;

    path;

    pages = {};
    selectedPage = null;


    constructor(
        client,
        sioClient,
        ea
    ) {
        this.client = client;
        this.sioClient = sioClient;
        this.ea = ea;
    }

    async bind() {
        const urlParams = new URLSearchParams(window.location.search);
        this.path = urlParams.get('path');

        await this.updateView();
    }

    async updateView() {
        this.pages = [];
        this.loading = true;

        let promise = null;

        if (this.sio) {
            promise = this.sioClient.get('https://sio-a1rc.s.io/api/v2/public/wiki/content-page?limit=500').then(
                response => this.extractJsonFromResponse(response).then(response => response.data),
                response => {
                    if (response instanceof DOMException && "AbortError" === response.name) {
                        throw response;
                    }
                    return this.extractJsonFromResponse(response).then(response => {
                        throw response;
                    });
                }
            )
        } else {
            promise = this.client.get('wiki/content-page?limit=500');
        }

        promise.then((data) => {

            console.log('DATA', data);

            this.pages = _.groupBy(data?.items ?? [], 'category');
            this.pagesBackup = data?.items ?? [];
            this.loading = false;

            let selectedPage = UrlUtils.getQueryParamValue(window.location.href, 'page');

            if (selectedPage) {
                this.selectedPage = null;

                for (let item of data?.items ?? []) {
                    if (item.id === selectedPage?.page) {
                        this.selectedPage = item;
                    }
                }
            }
        })
    }

    extractJsonFromResponse(response) {
        // if (!response.ok) {
        //     return Promise.reject({data: null});
        // }

        return response.text().then(data => {
            try {
                response.data = data.length != 0 ? JSON.parse(data) : null;
            } catch (e) {
                response.data = null;
            }

            return response;
        });
    }

    attached() {
        this.subscription = this.ea.subscribe('sio_form_post_submit', response => {

            if ('wiki/content-page' !== response.config.modelId) {
                return;
            }

            //Force clear cache, because order of subscribers is not fixed, and sometimes clear is not called before
            this.client.removeCache(response.config.modelId);
            this.client.removeCache('model/choice/' + response.config.modelId);

            this.updateView().then(data => {

            });
        });
    }

    detached() {
        this.subscription.dispose();
    }

    selectPage(page) {
        this.selectedPage = page;

        let newurl = UrlUtils.upsertQueryParamValue(
            window.location.href,
            'page',
            {page: page.id}
        );

        WindowUtils.updateLocationHrefWithoutReloading(newurl);
    }

    onFrameLoad() {
    }

    searchPage(event) {
        const filteredPages = this.pagesBackup?.filter((page) =>
            page.title?.toLowerCase().includes(event.target.value.toLowerCase())
        );
        this.pages = _.groupBy(filteredPages ?? [], 'category');
    }
}

