import {InlineViewStrategy, containerless, customElement, bindable} from "aurelia-framework";

@containerless
@customElement('sio-inline-view')
export class InlineView
{
    @bindable context;
    @bindable code;

    inlineViewStrategy(code)
    {
        return new InlineViewStrategy('<template>' + code + '</template>');
    }
}
