import {inject, InlineViewStrategy, PLATFORM, RelativeViewStrategy} from 'aurelia-framework';
import {Client} from '../api/client';
import {UserClient} from "../api/user-client";

const TYPES = Object.freeze({
    bulkForm: PLATFORM.moduleName('./type/bulkForm.html'),
    detail: PLATFORM.moduleName('./type/detail.html'),
    form: PLATFORM.moduleName('./type/form.html'),
    list: PLATFORM.moduleName('./type/list.html')
});

@inject(Client, UserClient)
export class ViewContextResolver {
    constructor(client, userClient) {
        this.client = client;
        this.userClient = userClient;
    }

    resolveViewContext(view, params) {
        if (view.type === 'detail') {

            let embeds = view.embeds ? view.embeds : [];

            return this.client.get(view.modelId + '/' + params.id + '?' + $.param({embeds: embeds}), 60).then(data => {

                let context = {};

                context.object = data;
                context.contextObjectRef = params.contextObjectRef || null;
                context.view = view;
                context.user = this.userClient.getUser();

                return context;
            });
        }

        let context = {};

        context.contextObjectRef = params.contextObjectRef || null;
        context.view = view;

        return Promise.resolve(context);
    }

    resolveViewStrategy(view) {
        if (view.content != null) {
            return new InlineViewStrategy('<template>' + view.content + '</template>');
        }

        if (!TYPES.hasOwnProperty(view.type)) {
            throw new Error('Unknown view type: ' + view.type);
        }

        return new RelativeViewStrategy(TYPES[view.type]);
    }
}
