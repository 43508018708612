import {bindable, customElement, inject, InlineViewStrategy, LogManager, PLATFORM} from "aurelia-framework";
import {ConditionMatcher} from "../../condition-builder/condition-matcher.js";
import {ModuleConfigClient} from "../../api/module-config-client.js";
import {UserClient} from "../../api/user-client";
import {PermissionClient} from "../../api/permission-client";
import * as _ from 'lodash';

const logger = LogManager.getLogger('Panel');

@customElement('sio-panel')
@inject(
    ConditionMatcher,
    ModuleConfigClient,
    UserClient,
    PermissionClient
)
export class Panel {
    @bindable config;
    @bindable object;

    _config;

    constructor(
        matcher,
        configClient,
        userClient,
        permissionClient
    ) {
        this.matcher = matcher;
        this.configClient = configClient;
        this.userClient = userClient;
        this.permissionClient = permissionClient;
    }

    async bind(context, overrideContext) {
        this.context = context;
        this.overrideContext = overrideContext;

        if (this.object == null) {
            return;
        }

        this._config = "string" !== typeof this.config ? this.config :
            (await this.configClient.getModel(this.object.modelId)).display.panels.find(panel => panel.id === this.config);

        if (!this._config) {
            return;
        }

        this._config = Object.assign({hidden: false}, this._config);
        if (this._config.hasOwnProperty("customBody")) {
            this._config.viewStrategy = new InlineViewStrategy('<template>' + this._config.customBody + '</template>');
        }

        if (this._config.hasOwnProperty("customHeader")) {
            this._config.headerViewStrategy = new InlineViewStrategy('<template>' + this._config.customHeader + '</template>');
        }

        this._checkDisplay();

        logger.debug('Binding changed', this);
    }

    objectChanged() {
        this._checkDisplay();
    }

    displayProperties(config) {
        return config.fields.map(field => field.id);
    }

    _getContext(object) {
        if (this._config.hasOwnProperty('actionContext')) {
            return this._config.actionContext;
        }

        // deep clone here, not using json because of bugs
        const context = _.cloneDeep(object);

        if (!context.hasOwnProperty('contextObjectRef') || !context.contextObjectRef.hasOwnProperty('id')) {
            context.contextObjectRef = {
                id: object.id,
                modelId: object.modelId,
            };
        }

        if (context.content) {
            console.log('Warning content property provided, deleting as it breaks views for cms detail pages');
            //Bugfix for cms-page detail page, where content of page overwrites view content
            delete context.content;
        }

        return context;
    }

    async _checkDisplay() {
        let hidden = false;
        let hideAction = false;

        if (this._config.conditions && !this.matcher.matchConditions(this.object, this._config.conditions)) {
            hidden = true;
        }

        if (!hidden && this._config.permissions && this._config.permissions.permissions) {
            if (!(await this.userClient.hasRole(this._config.permissions.permissions))) {
                hidden = true;
            }
        }

        if (!hidden && this._config.permissions && this._config.permissions.conditions) {
            if (!(await this.permissionClient.matchesPermissionCondition(this.object, this._config.permissions.conditions))) {
                hidden = true;
            }
        }

        if (this._config?.hidden) {
            hidden = true;
        }

        if (hidden) {
            hideAction = true;
        }

        if (!hideAction && this._config.action?.conditions && !this.matcher.matchConditions(this.object, this._config.action.conditions)) {
            hideAction = true;
        }

        if (!hideAction && this._config.action?.permissions?.permissions) {
            if (!(await this.userClient.hasRole(this._config.action.permissions.permissions))) {
                hideAction = true;
            }
        }

        if (!hideAction && this._config.action?.permissions?.conditions) {
            if (!(await this.permissionClient.matchesPermissionCondition(this.object, this._config.action.permissions.conditions))) {
                hideAction = true;
            }
        }

        this._config.hidden = hidden;
        this._config.hideAction = hideAction;
    }
}

PLATFORM.moduleName('view/view-container');
