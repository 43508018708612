export class PhysicalSizeValueConverter
{
    toView(size)
    {
        if (!size) {
            return '';
        }

        let unitOfMeasure = 'MB';

        if (size / (1024 * 1024) < 1) {
            unitOfMeasure = 'KB';
        }

        if (size / 1024 < 1) {
            unitOfMeasure = 'B';
        }

        let formattedSize;

        switch (unitOfMeasure) {
            case 'B':
                formattedSize = size;
                break;
            case 'KB':
                formattedSize = parseFloat(size / 1024).toFixed(2);
                break;
            case 'MB':
                formattedSize = parseFloat(size / (1024 * 1024)).toFixed(2);
                break;
        }

        return formattedSize + ' ' + unitOfMeasure;
    }
}
