import {LogManager} from "aurelia-framework";
import {ConvertYoutubeUrl} from "./url-converters/convert-youtube-url";

const logger = LogManager.getLogger('UrlToVideoReference');
logger.setLevel(LogManager.logLevel.none); // Disable logging for this class by setting "LogManager.logLevel.none"

export class UrlToVideoReferenceValueConverter
{
    constructor()
    {
        this.converters = {
            'youtube': new ConvertYoutubeUrl()
        };
    }

    fromView(value)
    {
        logger.debug('Convert value', value);

        if (!value) {
            return null;
        }

        for (let type in this.converters) {
            let converter = this.converters[type];

            let result = converter.fromString(value);

            if (null !== result) {
                return result;
            }
        }

        return null;
    }

    toView(value)
    {
        if (!value || !value.code || !value.type || !this.converters[value.type]) {
            return null;
        }

        let converter = this.converters[value.type];

        return converter.toString(value);
    }
}
