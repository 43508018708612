export class EscapeHtmlValueConverter
{
    toView(value)
    {
        const replacements = {
            '&': '&amp;',
            '<': '&lt;',
            '>': '&gt;',
            '"': '&quot;',
            "'": '&#039;'
        };

        return value.replace(/[&<>"']/g, function(m) { return replacements[m]; });
    }
}
