import {inject} from "aurelia-framework";
import {UrlUtils} from "../../utilities/url-utils";

@inject(UrlUtils)
export class SelectedTabStorage {

    constructor(urlUtils) {
        this.urlUtils = urlUtils;
    }

    getSelectedTab(allTabs) {
        const selectedTabId = new URLSearchParams(document.location.search.substring(1)).get('selectedTab');

        return allTabs.find(tab => tab.id === selectedTabId);
    }

    selectTab(tab) {
        // Todo: Atm it doesn't consider what tabs component notified about selection change
        // Thus if two tab panels are on same page, the last selected will overwrite the
        // value of query param. This should be fixed in future either by specifying which
        // sio-new-tabs can modify query params or by keeping query params for each
        // tabs control (latter solution is easier to implement and maintain, thus better)

        this.urlUtils.setParams({selectedTab: tab?.id || ''});
    }
}
