import {LogManager} from "aurelia-framework";

const logger = LogManager.getLogger('ConvertYoutubeUrl');
logger.setLevel(LogManager.logLevel.none); // Disable logging for this class by setting "LogManager.logLevel.none"

export class ConvertYoutubeUrl
{
    fromString(url)
    {
        const regex = /(?:(?:http|https):\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/watch\?v=(.*)/;
        const matches = url.match(regex);

        logger.debug('Matches', matches);

        if (null != matches) {
            return {
                type: 'youtube',
                code: matches[1]
            };
        }

        return null;
    }

    toString(value)
    {
        return 'https://www.youtube.com/watch?v=' + value.code;
    }
}
