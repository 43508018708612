export class SortValueConverter {
    toView(array, property, direction) {
        if (!array)
            return array;
        let factor = direction.match(/^desc*/i) ? 1 : -1;
        return array.sort((a, b) => {
            let textA = a.toUpperCase ? a[property].toUpperCase() : a[property];
            let textB = b.toUpperCase ? b[property].toUpperCase() : b[property];

            //put all unsetted values on top
            if (textA === undefined) {
                return -1;
            }

            return (textA < textB) ? factor : (textA > textB) ? -factor : 0;
        });
    }
}
