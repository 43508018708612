import {bindable, computedFrom} from "aurelia-framework";

export class Videoplayer
{
    @bindable video;

    @computedFrom('video')
    get type()
    {
        const video = this.video;

        if (!video || !video.type) {
            return 'unknown';
        }

        return video.type;
    }
}
