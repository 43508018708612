import {noView} from "aurelia-framework";

@noView
export class ViewContainer
{
    dialogController;

    activate(context)
    {
        console.debug('context', context);

        Object.assign(this, context);

        console.debug('view-container', this);
    }

    finishContext(event)
    {
        if (this.dialogController) {
            this.dialogController.ok(event?.detail?.response?.data);
        }
    }

}
